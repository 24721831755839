<template>
  <div class="monitoringList">
    <h3 class="page_name">随手拍详情</h3>
    <van-cell-group>
      <van-cell >
      			<template #value>
      			 <span class="van-cell__title">单据编号:</span>
      			 <span class="van-cell__value" style="color: #00b500">{{params.randomCode}}</span>
      			</template> 
      </van-cell>
      <van-cell>
      		  <template #value>
      		   <span class="van-cell__title">项目名称:</span>
      		   <span class="van-cell__value">{{params.siteName}}</span>
      		  </template> 
      </van-cell>
      <van-cell title="提交人" :value="params.createBy" />
      <van-cell title="提交时间" :value="params.createTime" />
      <van-cell title="整改负责人" :value="params.userName" />
      <van-cell title="手机号码" :value="params.phoneNumber" />
      <van-cell title="整改期限" :value="params.reformDate" />
      <van-cell title="问题项" :value="params.inspectionItems" />
      <van-cell title="问题描述" :value="checkContent" :label="params.checkContent" />
      <div class="question_img">
        <van-cell title="问题照片" :value="qs_img" />
        <img  @click="previewImage(item)" v-for="(item,index) in params.docList" :key="index" style="width:3rem;height:3rem" :src="item.fileUrl" alt="">
      </div>
      <div class="question_img">
        <van-cell title="整改完成照片" :value="wc_img" />
        <img  @click="previewImage(item)" v-for="(item,index) in params.rectifyDocList" :key="index" style="width:3rem;height:3rem" :src="item.fileUrl" alt="">
      </div>
      <van-cell title="审批人" :value="params.solvedUser" />
      <van-cell title="审批时间" :value="params.solvedDate" />
	  
	  </van-cell-group>
	  <van-cell-group title="处理过程">
	  <van-steps direction="vertical"  :active="activeIndex">
	    <van-step  v-for="(item,index) in params.logList" :key='index'>
	      <span class="van-step_title">【{{item.createBy}}】{{item.checkContent}}</span>
	  	<span class="van-step_status">
	  		<span :class="{'skyBlue':item.reformStatus==='0','orange':item.reformStatus==='1','red':item.reformStatus==='2','green':item.reformStatus==='3','blue':item.reformStatus==='4'}">
	  			{{getStatusLabel(item.reformStatus)}}
	  		</span>
	  	</span>
	      <p class="van-step_time">{{item.createTime}}</p>
	    </van-step>
	   
	  </van-steps>
    </van-cell-group>

  </div>
</template>
<script>
import { ImagePreview } from "vant";
import htmlApi from '../api/htmlApi'
export default {
  data() {
    return {
      checkContent:'',
      qs_img:'',
      params:{
        // 单号
        randomCode: "",
        // 项目名称
        siteName: "",
        // 检查人
        createBy: "",
        // 检查时间
        createTime: "",
        // 整改负责人
        userName: "",
        // 检查结果
        checkResult: "",
        // 整改期限
        reformDate: "",
        // 审批时间
        updateTime: "",
        // 提醒人员手机号码
        phoneNumber:'',
        // 问题描述
        inspectionItems:'',
        // 问题照片
        docList:[],
		//整改照片
		rectifyDocList:[]
      },
		billId: 0 || window.sessionStorage.getItem('billId'),
    };
  },
  mounted(){
    // this.getRandomInfo();
  },
  created() {
		console.log(this.$route.query.id);
		if (this.$route.query.id) {
		  window.sessionStorage.setItem('billId', this.$route.query.id)
		  this.billId = this.$route.query.id
		}
		 this.getRandomInfo();
	},
  methods:{
    async getRandomInfo(){
      const {code,msg,data} = await htmlApi.getRandomInfo(this.billId)
      if(code==200){
        console.log(data)
        this.params=data
        if(this.params.docList==''){
          this.qs_img='暂无数据'
        }else{
          this.qs_img=''
        }
        if(this.params.checkContent==null){
          this.checkContent='暂无数据'
        }else{
          this.checkContent=''
        }
        if(this.params.logList==''){
          this.wc_img='暂无数据'
        }else{
          this.wc_img=''
        }
      }else{

      }
    },
	previewImage(item){
		ImagePreview({
		        images: [item.fileUrl],
		        showIndex: true,
		        loop: false,
		        startPosition: 0
		      });
	} ,
	getStatusLabel(reformStatus){
		if(reformStatus=='0'){
			return '新增'
		}else if(reformStatus=='1'){
			return '待确认'
		}else if(reformStatus=='2'){
			return '重新整改'
		}else if(reformStatus=='3'){
			return '已解决'
		}else if(reformStatus=='4'){
			 return '检查合格'
		}else{
			return "未知状态"
		}
	},
  },
};
</script>
<style lang="scss" scoped>
.monitoringList {
  height: 100%;
  width: 100%;
  .question_img {
    img {
      margin-left: 0.21rem;
    }
  }
  
  .van-step_title ,.van-step_time{
  	  background-color:"#b0b1b3" !important;
  	  font-size: 12px;
  	  color:"#b0b1b3" !important;
  	}
  .van-step_status{
  	  position: absolute;
  	  right: 0px;
  	  width: 80px;
  	  span{
  		  //background-color:"#b0b1b3" !important;
  		  font-size: 14px;
  		  padding: 5px;
  		  border-radius: 5px;
  		  &.skyBlue{
  			background-color: #007AFF;
  		  }
  		  &.orange{
  			background-color: #ffc802;
  		  }
  		  &.red{
  			background-color: #f00000;
  		  }
  		  &.blue{
  			background-color: #4A94F5;
  		  }
  		  &.green{
  			background-color: #55ff00;
  		  }
  	  }
  }
  .van-cell__title{
  	  width: 50px;
    }
  .van-cell__value{
  	   position: absolute;
  	   right: 0px;
  	   width: 280px;
  }
  
}
</style>

