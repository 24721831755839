<template>
  <div class="monitoringList">
    <h3 class="page_name">检查单详情</h3>
    <van-cell-group >
      <van-cell >
			<template #value>
			 <span class="van-cell__title">单据编号:</span>
			 <span class="van-cell__value" style="color: #00b500">{{params.checkCode}}</span>
			</template> 
	  </van-cell>
      <van-cell>
		  <template #value>
		   <span class="van-cell__title">项目名称:</span>
		   <span class="van-cell__value">{{params.siteName}}</span>
		  </template> 
	  </van-cell>
	  
      <van-cell title="检查人:" :value="params.createBy" />
      <van-cell title="检查时间:" :value="params.createTime" />
	  <van-cell>
		  <template #value>
		   <span class="van-cell__title">整改单位:</span>
		   <span class="van-cell__value">{{params.companyName}}</span>
		  </template> 
	  </van-cell>
	  <van-cell title="抄送人:" :value="params.accreditUserName" />
      <van-cell title="整改负责人:" :value="params.userName" />
      <van-cell
        title="手机号码:"
        :value="params.phonenumber"
      />
      <van-cell title="检查结果:" :value="params.checkResult" />
      <van-cell title="整改期限:" :value="params.reformDate" />
      <!-- <van-cell title="整改时效:" value="24小时" /> -->
      <div>
        <van-cell title="问题检查项" />
		<van-collapse v-model="current" accordion>
		  <van-collapse-item :title="item[0]" :label="item[1]" :name="i"  v-for="(item, i) in params.checkProblem">
		    {{item[2]}}
		  </van-collapse-item>
		</van-collapse>
      
      </div>
      <van-cell title="问题描述" value="" :label="params.checkContent" />
      <div class="question_img">
        <van-cell title="问题照片" :value="qs_img" />
		<span v-for="(item, index) in params.docList">
			<img
			  v-if="item.logDocType==1"
			  :key="index"
			  style="width: 3rem; height: 3rem"
			  :src="item.fileUrl"
			  alt=""
			  @click="previewImage(item)"
			/>
		</span>
      </div>
      <div class="question_img">
        <van-cell title="整改完成照片" value="" />
		<span v-for="(item, index) in params.docList">
			<img
			  :key="index"
			  v-if="item.logDocType==2"
			  style="width: 3rem; height: 3rem"
			  :src="item.fileUrl"
			  alt=""
			   @click="previewImage(item)"
			/>
		</span>
      </div>
	  <div class="question_img">
	    <van-cell title="验收凭证" value="" />
	  		<span v-for="(item, index) in params.docList">
	  			<img
	  			  :key="index"
	  			  v-if="item.logDocType==3"
	  			  style="width: 3rem; height: 3rem"
	  			  :src="item.fileUrl"
	  			  alt=""
	  			   @click="previewImage(item)"
	  			/>
	  		</span>
	  </div>
      <van-cell title="审批人" :value="params.solvedUser" />
      <van-cell title="审批时间" :value="params.solvedDate" />
    </van-cell-group>
	<van-cell-group title="处理过程">
	<van-steps direction="vertical"  :active="activeIndex">
	  <van-step  v-for="(item,index) in params.logList" :key='index'>
	    <span class="van-step_title">【{{item.createBy}}】{{item.checkContent}}</span>
		<span class="van-step_status">
			<span :class="{'skyBlue':item.reformStatus==='0','orange':item.reformStatus==='1','red':item.reformStatus==='2','green':item.reformStatus==='3','blue':item.reformStatus==='4'}">
				{{getStatusLabel(item.reformStatus)}}
			</span>
		</span>
	    <p class="van-step_time">{{item.createTime}}</p>
	  </van-step>
	 
	</van-steps>
	</van-cell-group>
  </div>
</template>
<script>
	import { ImagePreview } from "vant";
import htmlApi from "../api/htmlApi";
export default {
	name: "ImagePreview ",
  data() {
	  
    return {
      current: 0,
	  activeIndex:0,
      qs_img: "",
      params: {
        // 单号
        checkCode: "",
        // 项目名称
        siteName: "",
        // 检查人
        createBy: "",
        // 检查时间
        createTime: "",
        // 整改负责人
        userName: "",
        // 检查结果
        checkResult: "",
        // 整改期限
        reformDate: "",
        // 问题检查项
        checkProblem: [],
        // 审批时间
        updateTime: "",
        docList: [],
        zgList: [],
      },
      billId: 0 || window.sessionStorage.getItem("billId"),
    };
  },
  mounted() {
    // this.getCheckListInfo();
  },
  created() {
	console.log(window.baseUrl);
	if(this.$route.query.url){
		  window.baseUrl = this.$route.query.url;
	}
	console.log(window.baseUrl);
    console.log(this.$route.query.id);
    if (this.$route.query.id) {
      window.sessionStorage.setItem("billId", this.$route.query.id);
      this.billId = this.$route.query.id;
    }
     this.getCheckListInfo();
  },
  methods: {
    async getCheckListInfo() {
		//this.billId= 19
      const { code, msg, data } = await htmlApi.getCheckListInfo(this.billId);
      if (code == 200) {
        
		console.log(data);
		this.params = data;
		//let zg=this.params.zgList
		console.log(data.logList)
		this.activeIndex = data.logList.length-1;
		// zg.fileUrl=data.logList
		/* let arr=data.logList;
		arr.filter((item)=>arr.reformStatus==1 && arr.reformStatus==4)
		console.log(arr) */
		if (this.params.checkResult == 0) {
		  this.params.checkResult = "正常";
		} else {
		  this.params.checkResult = "存在隐患";
		}
		this.params.checkProblem = JSON.parse(data.checkProblem);
		console.log(this.params.checkProblem);
		if (this.params.docList == null) {
		  this.qs_img = "暂无数据";
		} else {
		  this.qs_img = "";
		}
      } else {
      }
	  
    },
	getStatusLabel(reformStatus){
		if(reformStatus=='0'){
			return '新增'
		}else if(reformStatus=='1'){
			return '待确认'
		}else if(reformStatus=='2'){
			return '重新整改'
		}else if(reformStatus=='3'){
			return '已解决'
		}else if(reformStatus=='4'){
			 return '检查合格'
		}else{
			return "未知状态"
		}
	},
	previewImage(item){
		ImagePreview({
		        images: [item.fileUrl],
		        showIndex: true,
		        loop: false,
		        startPosition: 0
		      });
	} 
  },
};
</script>
<style lang="scss" scoped>

.monitoringList {
 
  
  .question_img {
    img {
      margin-left: 0.21rem;
    }
  }
  .van-step_title ,.van-step_time{
	  background-color:"#b0b1b3" !important;
	  font-size: 12px;
	  color:"#b0b1b3" !important;
	}
  .van-step_status{
	  position: absolute;
	  right: 0px;
	  width: 80px;
	  span{
		  //background-color:"#b0b1b3" !important;
		  font-size: 14px;
		  padding: 5px;
		  border-radius: 5px;
		  &.skyBlue{
			background-color: #007AFF;
		  }
		  &.orange{
			background-color: #ffc802;
		  }
		  &.red{
			background-color: #f00000;
		  }
		  &.blue{
			background-color: #4A94F5;
		  }
		  &.green{
			background-color: #55ff00;
		  }
	  }
  }
  .van-cell__title{
  	  width: 50px;
    }
  .van-cell__value{
  	   position: absolute;
	   right: 0px;
	   width: 280px;
  }
}

</style>

