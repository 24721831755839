<template>
    <div class="home">
        <div @click="go_ssp">随手拍详情页</div>
        <div @click="go_jc">检查单详情页</div>
        <div @click="go_yd">移动巡检详情页</div>
		<div @click="go_user">工人详情</div>
    </div>
</template>
<script>
export default {
    data(){
        return{

        }
    },
    methods:{
        go_ssp(){
            this.$router.push({
                path:'/SnapshotDetail'
            })
        },
        go_jc(){
            this.$router.push({
                path:'/Checklist'
            })
        },
        go_yd(){
            this.$router.push({
                path:'/MobileInspection'
            })
        },
		go_user(){
		    this.$router.push({
		        path:'/userIndex'
		    })
		},
    }
}
</script>
<style scoped>
.home{
    font-size: 15px;
    display: flex;
    justify-content: space-between;
}
</style>